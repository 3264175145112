export const navbarLinks = [
  {
    name: "رياضة",

    link: "https://almashhad.com/section/218257510540304-sports/",
  },

  {
    name: "منوعات",

    link: "https://almashhad.com/section/459217420284850-entertainment/",
  },

  {
    name: "اقتصاد",

    link: "https://almashhad.com/section/174515324408726-economy/",
  },

  {
    name: "أخبار",

    link: "https://almashhad.com/section/773112298002792-News/",
  },

  {
    name: "الرئيسية",

    link: "https://almashhad.com/",
  },
];
