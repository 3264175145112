export const presenters=[
    {
    id: 0, 
    presenterSrc: "./presenters/tonykhalifa.png",
    presenterName: "طوني خليفة",
}, 
{
id: 1, 
    presenterSrc: "./presenters/HIBA.png",
    presenterName: "هبه حيدري",
},
{
id: 2, 
presenterSrc: "./presenters/MOHAMED-QAIS.png",
presenterName:"محمد قيس",
}, 
{
id: 3, 
    presenterSrc: "./presenters/MAJDALA.png",
    presenterName: "مجدلا خطار",
},
{
    id: 4, 
    presenterSrc: "./presenters/MOHAMED-ABU-OBEID.png",
    presenterName: "محمد أبو عبيد",
}, 
{
id: 5, 
    presenterSrc: "./presenters/MAIS.png",
    presenterName: "ميس محمد",
},
{
id: 6, 
presenterSrc: "./presenters/HANI-NAQSHABANDI.png",
presenterName:"هاني النقشبندي",
}, 
{
id: 7, 
    presenterSrc: "./presenters/MOATAZ.png",
    presenterName: "معتز عبد الفتاح",
},
{
    id: 8, 
    presenterSrc: "./presenters/HAYA.png",
    presenterName:"هيا محفوظ",
    }, 
    {
    id: 9, 
        presenterSrc: "./presenters/ZIAD.png",
        presenterName: "زياد نجيم",
    }
]