export const shows = [
  {
      id: 0, 
      src: "./presntershows/studioalarab.jpg",
      showName: "استوديو العرب",
      description:
        "بودكاست اجتماعيّ تقدّمه ميس محمد، ويتمحور حول سبل تحفيز الذات وتحسين حياتنا",
  },
  {
      id: 1, 
      src: "./presntershows/tawatorali.jpg",
      showName: "توتر عالي",
      description: "بودكاست من تقديم هبة حيدري، حيث ستستضيف عددا من نجوم قناة ومنصة \"المشهد\"، وتسلط الضوء على قصص لا تعرفونها عن وجوه معروفة"
  },
  {
    id:2, 
    src: "",
    showName:"",
    description:"بودكاست من تقديم محمد قيس، يقوم باستضافة فنانين ومشاهير للحديث أكثر عن حياتهم الفنيّة والمهنيّة والشخصيّة"
  },
  {
      id: 3, 
      src: "./presntershows/mennawfina.jpg",
  showName:"منا وفينا",
  description:
    "   و هذه المدن مقاومة. الأولية التقليدي عدد مع, كل أمام اتّجة وانتهاءً إيو. ليبين والديون ما أما, إذ حول ترتيب اليابان الأوروبيّون. أي جُل ",
  }, 
  {
      id: 4, 
      src: "./presntershows/qissetoughnia.jpg",
  showName: "قصة أغنية",
  description:"بودكاست من تقديم سارة برحوش، تستقبل فيه أمهات مررنَ بتجارب غير عادية في أمومتهنّ"
  }, 
  {
      id: 5, 
      src: "",
  showName: "",
  description:
  "",
  }, 
  {
      id: 6, 
      src:  "./presntershows/jeelnagair.jpg",
      showName:"جيلنا غير",
      description:
        "   و هذه المدن مقاومة. الأولية التقليدي عدد مع, كل أمام اتّجة وانتهاءً إيو. ليبين والديون ما أما, إذ حول ترتيب اليابان الأوروبيّون. أي جُل ",
  },
  {
      id: 7, 
      src: "./presntershows/aandisoual.jpg",
      showName: "عندي سؤال",
      description:
        "   و هذه المدن مقاومة. الأولية التقليدي عدد مع, كل أمام اتّجة وانتهاءً إيو. ليبين والديون ما أما, إذ حول ترتيب اليابان الأوروبيّون. أي جُل ",
  },
  {
      id: 8, 
      src: "./presntershows/almashhadtag.jpg",
      showName: "المشهد تاج",
      description:
        "   و هذه المدن مقاومة. الأولية التقليدي عدد مع, كل أمام اتّجة وانتهاءً إيو. ليبين والديون ما أما, إذ حول ترتيب اليابان الأوروبيّون. أي جُل ",
  },
  {
      id: 9, 
      src: "./presntershows/naseeah.jpg",
      showName:"نصيحة",
      description:
        "   و هذه المدن مقاومة. الأولية التقليدي عدد مع, كل أمام اتّجة وانتهاءً إيو. ليبين والديون ما أما, إذ حول ترتيب اليابان الأوروبيّون. أي جُل ",
  },
  {
      id: 10, 
      src: "./presntershows/haqalard.jpg",
      showName: "حق الأرض",
      description:
        "   و هذه المدن مقاومة. الأولية التقليدي عدد مع, كل أمام اتّجة وانتهاءً إيو. ليبين والديون ما أما, إذ حول ترتيب اليابان الأوروبيّون. أي جُل ",
  },
  {
      id: 11, 
      src:  "",
      showName:"",
      description:"",
  },
  {
    id: 12, 
    src: "./presntershows/studioalarab.jpg",
    showName:"استوديو العرب",
    description:
      "بودكاست اجتماعيّ تقدّمه ميس محمد، ويتمحور حول سبل تحفيز الذات وتحسين حياتنا",
},
{
    id: 13, 
    src: "./presntershows/stratigia.jpg",
    showName: "استراتيجيا",
    description: "بودكاست من تقديم هبة حيدري، حيث ستستضيف عددا من نجوم قناة ومنصة \"المشهد\"، وتسلط الضوء على قصص لا تعرفونها عن وجوه معروفة"
},
{
  id:14, 
  src: "./presntershows/tibawayah.jpg",
  showName:"طيبوية",
  description:"بودكاست من تقديم محمد قيس، يقوم باستضافة فنانين ومشاهير للحديث أكثر عن حياتهم الفنيّة والمهنيّة والشخصيّة"
},
{
    id: 15, 
    src: "./presntershows/SORA-MAN-RAA.jpg",
showName:"سّر من رأى",
description:
  "   و هذه المدن مقاومة. الأولية التقليدي عدد مع, كل أمام اتّجة وانتهاءً إيو. ليبين والديون ما أما, إذ حول ترتيب اليابان الأوروبيّون. أي جُل ",
}, 
{
    id: 16, 
    src: "./presntershows/mamais.jpg",
showName: "مع ميس",
description:"بودكاست من تقديم سارة برحوش، تستقبل فيه أمهات مررنَ بتجارب غير عادية في أمومتهنّ"
}, 
{
    id: 17, 
    src: "",
showName: "",
description:
"",
}, 
{
    id: 18, 
    src:  "./presntershows/hanawati.jpg",
    showName: "هنواتي",
    description:
      "   و هذه المدن مقاومة. الأولية التقليدي عدد مع, كل أمام اتّجة وانتهاءً إيو. ليبين والديون ما أما, إذ حول ترتيب اليابان الأوروبيّون. أي جُل ",
},
{
    id: 19, 
    src:  "./presntershows/khalijna.jpg",
    showName: "خليجنا",
    description:
      "   و هذه المدن مقاومة. الأولية التقليدي عدد مع, كل أمام اتّجة وانتهاءً إيو. ليبين والديون ما أما, إذ حول ترتيب اليابان الأوروبيّون. أي جُل ",
},
{
    id: 20, 
    src: "",
    showName: "",
    description:"",
},
{
    id: 21, 
    src: "./presntershows/studioalarab.jpg",
    showName: "استوديو العرب",
    description:
      "   و هذه المدن مقاومة. الأولية التقليدي عدد مع, كل أمام اتّجة وانتهاءً إيو. ليبين والديون ما أما, إذ حول ترتيب اليابان الأوروبيّون. أي جُل ",
},
{
    id: 22, 
    src: "./presntershows/semfeassal.jpg",
    showName: "سم في عسل",
    description:
      "   و هذه المدن مقاومة. الأولية التقليدي عدد مع, كل أمام اتّجة وانتهاءً إيو. ليبين والديون ما أما, إذ حول ترتيب اليابان الأوروبيّون. أي جُل ",
},
{
    id: 23, 
    src: "",
    showName:"",
    description:"",
},
{
  id: 24, 
  src:  "./presntershows/gamez.jpg",
  showName: "جيميز",
  description:
    "   و هذه المدن مقاومة. الأولية التقليدي عدد مع, كل أمام اتّجة وانتهاءً إيو. ليبين والديون ما أما, إذ حول ترتيب اليابان الأوروبيّون. أي جُل ",
},
{
  id: 25, 
  src: "./presntershows/ajeebgaree.jpg",
  showName: "عجيب غريب",
  description:
    "   و هذه المدن مقاومة. الأولية التقليدي عدد مع, كل أمام اتّجة وانتهاءً إيو. ليبين والديون ما أما, إذ حول ترتيب اليابان الأوروبيّون. أي جُل ",
},
{
  id: 26, 
  src: "",
  showName: "",
  description:" ",
},
{
  id: 27, 
  src: "./presntershows/anaalshaab.jpg",
  showName: "أنا الشعب",
  description:
    "   و هذه المدن مقاومة. الأولية التقليدي عدد مع, كل أمام اتّجة وانتهاءً إيو. ليبين والديون ما أما, إذ حول ترتيب اليابان الأوروبيّون. أي جُل ",
},
{
  id: 28, 
  src: "",
  showName: "",
  description:" ",
},
{
  id: 29, 
  src: "",
  showName: "",
  description:" ",
},
]