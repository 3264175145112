import "./App.css";
import React, { useEffect, useState, useRef } from "react";
import Box from "@mui/material/Box";
import { CardActionArea, Container, Grid } from "@mui/material";
import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";
import AppBar from "@mui/material/AppBar";
import Toolbar from "@mui/material/Toolbar";
import Typography from "@mui/material/Typography";
import Button from "@mui/material/Button";
import IconButton from "@mui/material/IconButton";
// import Menu from "@mui/material/Menu";
import MenuIcon from "@mui/icons-material/Menu";
// import MenuItem from "@mui/material/MenuItem";
import Typical from "react-typical";
import Card from "@mui/material/Card";
import CardMedia from "@mui/material/CardMedia";
import PlayCircleIcon from "@mui/icons-material/PlayCircle";
import { allShows } from "./data/allShows";
import { navbarLinks } from "./data/navbarLinks";
import { shows } from "./data/shows";
import { presenters } from "./data/presenters";
import { motion } from "framer-motion";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemText from "@mui/material/ListItemText";
import Drawer from "@mui/material/Drawer";
import Link from '@mui/material/Link';

export default function App() {
  const slider = allShows;
  const showPresenter = shows;
  const presenter = presenters;
  const pages = navbarLinks;

  const [anchorElNav, setAnchorElNav] = useState(false);
  const [show, setShow] = useState(0);
  const [currentImageIndex, setCurrentImageIndex] = useState(
    presenter[0].presenterSrc
  );
  // const [presenterSrc, setPresenterSrc] = useState(
  //   presenter[0].presenterSrc
  // );
  const [presenterName, setPresenterName] = useState(
    presenter[0].presenterName
  );

  const presenterRef = useRef();
  const presenterRefName = useRef();

  const handleCloseNavMenu = () => {
    setAnchorElNav(false);
  };
  const toggleMenu = (event) => {
    setAnchorElNav(event.currentTarget);
  };


const settingShow=(currentSlide)=>{
  if (currentSlide == 3) setShow(1);
  else if (currentSlide == 6) setShow(2);
  else if (currentSlide == 9) setShow(3);
  else if (currentSlide == 12) setShow(4);
  else if (currentSlide == 15) setShow(5);
  else if (currentSlide == 18) setShow(6);
  else if (currentSlide == 21) setShow(7);
  else if (currentSlide == 24) setShow(8);
  else if (currentSlide == 27) setShow(9);
  else if (currentSlide == 30) setShow(10);
  else setShow(0);
}
  const container = {
    hidden: { opacity: 0 },
    show: {
      opacity: 1,
      scale: 1,
      transition: {
        duration: 0.5,
        delay: 0.1,
        ease: [0.7, 0.4, 0.4, 0],
      },
    },
    leave: {
      opacity: 0,
    },
  };



  
  useEffect(() => {
    const delay = setTimeout(() => {
      presenterRef.current.src = presenter[show].presenterSrc;
      presenterRefName.current.value = presenter[show].presenterName;
      const newId = presenter[show].presenterSrc;
      setPresenterName(presenterRefName.current.value);
      setCurrentImageIndex(newId);
  
    }, 300);

    return () => {
      clearTimeout(delay);
     
    };
  }, [show]);

  return (
    <>
      <Container maxWidth="xl" className="bgImage" disableGutters>
        <div className="bgOpacity">

        <AppBar
          className="appBar"
          style={{ background: "black", border: "none", boxShadow: "none" }}
          component="nav"
        >
          <Toolbar style={{ margin: "auto", maxWidth: 1500, width: "100%" }}>
            <Box sx={{ flexGrow: 1, display: { xs: "flex", md: "none" } }}>
              <IconButton
                size="large"
                aria-controls="menu-appbar"
                aria-haspopup="true"
                onClick={toggleMenu}
                color="inherit"
              >
                <MenuIcon />
              </IconButton>
              <Drawer
            anchor='left'
            open={anchorElNav}
            onClose={handleCloseNavMenu}
          >
 <Box
      sx={{ width: 250 }}
      role="presentation"
    >
                <List>
                {pages.map((text, index) => (
                  <ListItem key={text} disablePadding>
                    <ListItemButton>
                      <ListItemText primary={text.name} sx={{textAlign: 'center'}}/>
                    </ListItemButton>
                  </ListItem>
                ))}
              </List>      
</Box>
          </Drawer>
            </Box>

            <Box sx={{ flexGrow: 1, display: { xs: "none", md: "flex" } }}>
              {pages.map((page) => (
                <Button
                  key={page}
                  href={page.link}
                  sx={{
                    my: 2,
                    color: "white",
                    display: "block",
                    fontFamily: "Alilato-Medium !important",
                  }}
                >
                  {page.name}
                </Button>
              ))}
            </Box>

            <Box sx={{ flexGrow: 0 }} className="logoContainer" component={Link} href="https://almashhad.com/">
              <img src="./WhiteLogo.svg" className="logo" />
            </Box>
          </Toolbar>
        </AppBar>

        {/* Video Component */}

        <section className="outter option2">
          <section className="video-container">

            <video
              src="/promovideo.mp4"
              autoPlay
              loop
              preload="auto"
              muted
              playsInline
              className="video"
              // poster="testcarousel.png"
              type="video/mp4"
            />

            <div class="callout">
              <br></br>
              <Typical
               steps={["في انتظارك", 5000, "", 1000]}
                loop={Infinity}
                className="spanText"
                wrapper="b"
              />
              <span></span>
              <h1 style={{ color: "white", fontSize: "30px" }}>
                {" "}
                برامج جديدة لموسم استثنائي جديد{" "}
              </h1>
              <br></br>
            </div>

          </section>
        </section>

        <div style={{ marginTop: '15%' }} class="space"></div>

        <div class="carouselContainer">
          <h2 class="carouselHeader" style={{direction: 'rtl' }}>برامج من والى عالمنا العربي والخليجي</h2>
         
          <Carousel
            centerMode={true}
            additionalTransfrom={-20 * 7}
            arrows
            autoPlay={true}
            autoPlaySpeed={2000}
            className="carousel"
            dotListClass=""
            draggable={false}
            focusOnSelect={false}
            infinite
            itemClass=""
            renderArrowsWhenDisabled={false}
            renderButtonGroupOutside={false}
            renderDotsOutside={false}
            responsive={{
              desktop: {
                breakpoint: {
                  max: 10000,
                  min: 1201,
                },
                items: 3,
                partialVisibilityGutter: 40,
              },

              mobile: {
                breakpoint: {
                  max: 767,
                  min: 0,
                },
                items: 1,
                partialVisibilityGutter: 10,
              },

              tablet: {
                breakpoint: {
                  max: 1200,
                  min: 768,
                },

                items: 2,
                partialVisibilityGutter: 30,
              },
            }}
            rewind={false}
            rewindWithAnimation={false}
            rtl={true}
            shouldResetAutoplay
            showDots={false}
            sliderClass=""
            slidesToSlide={1}
            swipeable
          >
            {slider.map((slide) => (
              <>
                <div class="containerCard">
                  <Card
                    style={{ margin: 15, background: "transparent" }}
                    className="mainCard"
                  >
                    <CardActionArea href={slide.link}>
                      <CardMedia
                        className="imageCard"
                        sx={{ borderRadius: 5 }}
                        image={slide.src}
                      />
                    </CardActionArea>
                  </Card>
                  <Typography
                    style={{ textAlign: "center", color: "white", fontSize: '1.2rem', fontWeight: 900 }}
                    className="outsideText"
                  >
                    {" "}
                    {slide.showName}{" "}
                  </Typography>
                  <a href={slide.link} className="mobile">
                    <div class="middle">
                      <div class="text">
                        <h2 style={{ color: "yellow" }}>{slide.showName}</h2>

                        <p style={{ color: "white" }} class="mobile">
                          {slide.description}
                        </p>

                        <div
                          style={{
                            display: "flex",

                            flexDirection: "row-reverse",

                            justifyContent: "center",
                          }}
                          class="mobile"
                        >
                          <IconButton style={{ padding: "0px !important" }}>
                            <PlayCircleIcon style={{ color: "white" }} />
                          </IconButton>
                          <Typography
                            style={{ color: "white", marginLeft: 10 }}
                          >
                            {slide.showName}
                          </Typography>
                        </div>
                      </div>
                    </div>
                  </a>
                </div>
              </>
            ))}
          </Carousel>
        </div>

        

        <div style={{ marginTop: '15%' }} class="space"></div>

        <Grid
          container
          rowSpacing={1}
          columnSpacing={{ xs: 1, sm: 2, md: 3 }}
          className="presenterSection"
        >
          <Grid item xs={12} sm={12} md={6} className="gridItemPresenter">
            <motion.div
              key={currentImageIndex}

              className="presenterDiv"
              variants={container}
              initial="hidden"
              animate="show"
              exit="hidden"
              style={{
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
              }}
            >
              <img
                ref={presenterRef}
                src={currentImageIndex}
                style={{ height: "80%", width: "60%" }}
              />
              <h2
                ref={presenterRefName}
                style={{
                  textAlign: "center",
                  color: "white",
                  fontSize: "50px",
                  margin: 0,
                  marginTop: "-50px",
                }}
              >
                {presenterName}
              </h2>
            </motion.div>
          </Grid>

          <Grid item xs={12} sm={12} md={6}>

            <div class="Section" id="yellowText">

              <div className="header-carousel">
                <h2 className="headerCarouselName">
                تعرّف على نجوم المشهد  
                </h2>
{/* 
                {/* <Typical
                  steps={["في انتظارك", 5000, "", 1000]}
                  loop={Infinity}
                  className="spanTextPresenter"
                  wrapper="b"
                /> */}
              </div>

              <div className="presentercarousel">
                <Carousel
                  id="carouselPresenter"
                  arrows
                  // autoPlay={true}
                  // centerMode={true}
                  className="carousel"
                  dotListClass=""
                  draggable={false}
                  focusOnSelect={false}
                  itemClass=""
                  renderArrowsWhenDisabled={true}
                  renderDotsOutside={false}
                  responsive={{
                    desktop: {
                      breakpoint: {
                        max: 10000,
                        min: 1024,
                      },
                      items: 3,
                    },
                    mobile: {
                      breakpoint: {
                        max: 900,
                        min: 0,
                      },
                      items: 3,
                    },
                    tablet: {
                      breakpoint: {
                        max: 1024,
                        min: 901,
                      },
                      items: 3,
                    },
                  }}
                  rewind={true}
                  rewindWithAnimation={true}
                  rtl={true}
                  // shouldResetAutoplay
                  showDots={false}
                  sliderClass=""
                  slidesToSlide={3}
                  swipeable
                  afterChange={(previousSlide, { currentSlide, onMove }) => {
                    settingShow(currentSlide);
                  }}
                >
                  {showPresenter.map((page) => (
                    <>
                      <div class="containerCardPresenter">
                        <Card
                          style={{ margin: 5, background: "transparent" }}
                          className="mainCardPresenter"
                        >  
                          <CardMedia
                            className="imageCardPresenter"
                            sx={{ borderRadius: 5 }}
                            image={page.src}
                          >
                              <div className="bgCard"> </div>
                          </CardMedia>
                          <Typography
                            style={{ textAlign: "center", color: "white",  fontWeight: 900 }}
                            className="outsideTextPresenter"
                          >
                            {" "}
                            {page.showName}{" "}
                          </Typography>
                        </Card>
                      </div>
                    </>
                  ))}
                </Carousel>

                <hr
                  style={{
                    maxWidth: "500px",
                    position: "relative",
                    left: "10%",
                    marginTop: "-30px",
                  }}
                ></hr>

              </div>
            </div>

          </Grid>
        </Grid>

        <div style={{ marginTop: '15%' }} class="space"></div>

        <div
          style={{ background: "black", display: "flex", flexDirection: "row" }}
          class="followUsContainer"
        >
          <h2 style={{ color: "white", direction: "rtl", textAlign: "center", fontWeight: 'bold' }}>
            برامج جديدة لموسم استثنائي جديد في انتظارك!
          </h2>
          <img src="./followus.png" style={{ cursor: "pointer" }} />
        </div>

        <a href="https://almashhad.com">
          {" "}
          <img
            src="./FOOTER.png"
            style={{
              width: "100%",

              height: "100%",
              cursor: "pointer",
            }}
          />
        </a>

        </div>
      </Container>
    </>
  );
}
