export const allShows = [
  {
    id: 0, 
    src: "./shows/mamais.jpg",
    showName: "مع ميس",
    description:
     "بودكاست اجتماعيّ تقدّمه ميس محمد، ويتمحور حول سبل تحفيز الذات وتحسين حياتنا،",
     link: "https://www.youtube.com/@almashhadmedia",

},
{
    id: 1, 
    src: "./shows/mennawfina.jpg",
    showName:"منّا وفينا",
    description: "بودكاست من تقديم هبة حيدري، حيث ستستضيف عددا من نجوم قناة ومنصة \"المشهد\"، وتسلط الضوء على قصص لا تعرفونها عن وجوه معروفة",
    link: "https://www.youtube.com/@almashhadmedia",
},
{
  id:2, 
  src: "./shows/aandisoual.jpg",
  showName:"عندي سؤال",
  description:"بودكاست من تقديم محمد قيس، يقوم باستضافة فنانين ومشاهير للحديث أكثر عن حياتهم الفنيّة والمهنيّة والشخصيّة",
  link: "https://www.youtube.com/@almashhadmedia",
},

{
    id: 3, 
    src: "./shows/chatdrd.jpg",
    showName: "دردchat",
    description:" برنامج من تقديم خليل جمال وهيا محفوظ وهو عبارة عن جلسة شبابية ومواضيع متنوعة تهمّ الشباب العربي.",
    link: "https://www.youtube.com/@almashhadmedia",
}, 

  {
    id: 4,
    src: "./shows/stratigia.jpg",
    showName: "استراتيجيا",
    description:"برنامج يقدمه محمد أبو عبيد يشرح فيه بشكل مبسّط ومفصّل على الخريطة أهم القضايا التي تحمل أبعادا جيوسياسية",
    link: "https://www.youtube.com/@almashhadmedia",
  },

  {
    id:5,
    src: "./shows/khalijna.jpg",
    showName: "خليجنا",
    description:"برنامج من تقديم هاني نقشبندي يستعرض فيه أبرز الإنجازات والمستجدات والابتكارات في دول الخليج",
    link: "https://www.youtube.com/@almashhadmedia",
  },

  {
    id: 6,
    src: "./shows/makingof.jpg",
    showName: "Making Of",
    description:"برنامج وثائقي يأخذنا في رحلة الى عالم الصناعات الحديثة والتقليديّة",
    link: "https://www.youtube.com/@almashhadmedia",
    },

  {
    id: 7,
    src: "./shows/qissetoughnia.jpg",
    showName: "قصة أغنية",
    description:"هبة حيدري تقدم روايات لا تعرفونها حول قصص الأغاني الشهيرة",
    link: "https://www.youtube.com/@almashhadmedia",
  },
  {
    id: 8,
    src: "./shows/tibawayah.jpg",
    showName: "طيبويه",
    description: "يقوم محمد أبو عبيد، بتسليط الضوء بطريقته الخاصة حول أخطاء لغوية شائعة وأصول بعض الكلمات العربية",
    link: "https://www.youtube.com/@almashhadmedia",
  },

];